/**
 * @name 酒店列表
 */
import request from '@/utils/request'

export const getHotelsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/hotels/setup'
  })
}

export const getHotelsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/hotels',
    params
  })
}

export const deleteHotelsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/hotels/${id}`
  })
}

export const postHotelsAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/hotels',
    data
  })
}

export const getHotelsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/hotels/collections'
  })
}

export const updateHotelsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/hotels/${id}/online`
  })
}

export const updateHotelsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/hotels/${id}/offline`
  })
}

export const getHotelsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotels/${id}/preview`
  })
}

export const putHotelsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/hotels/${id}`,
    data
  })
}

export const getHotelsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotels/${id}`
  })
}

/**
 * @name 房间列表
 */

export const getRoomsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/hotel_rooms/setup'
  })
}

export const getRoomsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/hotel_rooms',
    params
  })
}

export const deleteRoomsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/hotel_rooms/${id}`
  })
}

export const postRoomsAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/hotel_rooms',
    data
  })
}

export const getRoomsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/hotel_rooms/collections'
  })
}

export const updateRoomsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/hotel_rooms/${id}/online`
  })
}

export const updateRoomsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/hotel_rooms/${id}/offline`
  })
}

export const getRoomsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotel_rooms/${id}/preview`
  })
}

export const putRoomsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/hotel_rooms/${id}`,
    data
  })
}

export const getRoomsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotel_rooms/${id}`
  })
}

/**
 * @name 政策列表
 */

export const getPolicySetup = () => {
  return request({
    method: 'GET',
    url: '/cms/hotel_room_policies/setup'
  })
}

export const getPolicyList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/hotel_room_policies',
    params
  })
}

export const deletePolicyDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/hotel_room_policies/${id}`
  })
}

export const postPolicyAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/hotel_room_policies',
    data
  })
}

export const getPolicyCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/hotel_room_policies/collections'
  })
}

export const updatePolicyOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/hotel_room_policies/${id}/online`
  })
}

export const updatePolicyOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/hotel_room_policies/${id}/offline`
  })
}

export const getPolicyEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotel_room_policies/${id}/preview`
  })
}

export const putPolicyFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/hotel_room_policies/${id}`,
    data
  })
}

export const getPolicyDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotel_room_policies/${id}`
  })
}

export const postPolicyCalendarPrice = (id, data) => {
  return request({
    method: 'POST',
    url: `/cms/hotel_room_policies/${id}/price`,
    data
  })
}

export const getPolicyCalendarPrice = (id, params) => {
  return request({
    method: 'GET',
    url: `/cms/hotel_room_policies/${id}/price_calendar`,
    params
  })
}
